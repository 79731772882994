import { mapActions, mapGetters } from 'vuex'
import validation from '@/mixins/validation'
import { camelCase, snakeCase } from 'lodash'

export default {
	name: 'course-setting-general',
	mixin: [validation],
	data () {
		return {
			validPayload: false,
			payload		: {
				timetableStartingOfGraduateWork	: false,
				timetableStartingOfHomeWork		: false,
				timetableStartingOfLesson		: false,
				hideRating						: false
			}
		}
	},
	computed: {
		...mapGetters({
			timetableStartingData			: 'coursesSetting/timetableStarting',
			timetableStartingLoading		: 'coursesSetting/timetableStartingLoading',
			updateTimetableStartingLoading	: 'coursesSetting/updateTimetableStartingLoading'
		})
	},
	created () {
		this.fetchTimetableStarting({
			id: this.$route.params.course_id
		})
		.then(() => {
			this.setContent()
		})
	},
	methods: {
		...mapActions({
			fetchTimetableStarting	: 'coursesSetting/GET_TIMETABLE_STARTING',
			updateTimetableStarting	: 'coursesSetting/UPDATE_TIMETABLE_STARTING'
		}),
		setContent () {
			const data = this.timetableStartingData
			const item = {}
			
			for (const i in data) {
				item[camelCase(i)] = data[i]
			}
			
			Object.assign(this.payload, item)
		},
		createRequestPayload () {
			const data = this.payload
			const item = {}
			
			Object.keys(data).forEach(e => {
				item[snakeCase(e)] = data[e]
			})
			
			return item
		},
		submit () {
			this.$refs.formPayload.validate()
			
			if (this.validPayload) {
				this.updateTimetableStarting(this.createRequestPayload()).then(() => {
					this.$toasted.success(this.$t('success_updated'))
				})
			}
		}
	}
}
